import React from "react";
import {
  Box,
  Flex,
  Container,
  Text,
  Image,
  HStack,
  Grid,
  Button,
  Skeleton,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
// import queryString from "query-string";
import illustration from "../../assets/img/portfolio-empty.png";
import { PrimaryButton } from "../../components/Buttons";
import { InvestedStartUpCard } from "../../components/StartUpCard";
import { MenuDropOption } from "../../components/Options";
import { retriveUserInvestments } from "../../utils/calls/investments.calls";
import {
  getCategoriesInvestments,
  getStagesInvestments,
} from "../../utils/calls/options";
import Sidebar from "./sidebar";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ShowFilterOptionsIcon } from "../../components/Icons";
import FilterModalMobile from "../../components/MobileModals/FilterModalMobile";
import OptionsModal from "../../components/MobileModals/OptionsModal";
// import { rejectSeries } from "async";
// import { stubFalse } from "lodash-es";
import apis from "../../utils/api";

let { VERIFICATION_API } = apis;
let baseUrl = VERIFICATION_API;

const Portfolio = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [investments, setinvestments] = React.useState([]);
  const token = JSON.parse(localStorage.getItem("token"));
  const { user } = useSelector(state => state.user);
  const history = useHistory();
  // const location = useLocation();
  const [loading, setloading] = React.useState(true);

  const [showFilter, setshowFilter] = React.useState(false);
  const [showOptions, setshowOptions] = React.useState(false);
  const [optionsType, setoptionsType] = React.useState("");

  const [stages, setstages] = React.useState([]);
  const [selectedStage, setselectedStage] = React.useState("");
  const [categories, setcategories] = React.useState([]);
  const [selectedCategory, setselectedCategory] = React.useState("");

  const launchVideoModal = () => {
    onOpen();
    localStorage.setItem("hasVisited", true);
  };

  React.useEffect(() => {
    if (!localStorage.getItem("hasVisited")) {
      launchVideoModal();
    }
  }, []);

  React.useEffect(() => {
    setloading(true);
    getInvestments(selectedCategory, selectedStage);
  }, [selectedCategory, selectedStage]);

  const getInvestments = (category, stage) => {
    retriveUserInvestments(user.id, category, stage)
      .then(response => {
        setloading(false);
        setinvestments(response.data);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      });
  };

  React.useEffect(() => {
    getInvestments();

    getCategoriesInvestments(user.id)
      .then(response => {
        setcategories(response.data);
        setselectedCategory("All Sectors");
      })
      .catch(err => {
        console.log(err);
      });

    getStagesInvestments(user.id)
      .then(response => {
        setstages(response.data);
        setselectedStage("All Stages");
      })
      .catch(err => {
        console.log(err);
      });
  }, [token, user.id]);

  return (
    <>
      <Container maxW='7xl' marginTop={[0, 0, 3, 14]}>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box width={["100%", "100%", "100%", "30%"]}>
            <Sidebar empty={false} />
          </Box>
          <Box width={["100%", "100%", "100%", "63%"]}>
            {/* loading */}
            {loading && (
              <Box pb='100px'>
                <Grid
                  gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]}
                  gap={["18px", "18px", "18px", "50px"]}
                >
                  {[1, 2, 3, 4].map(item => (
                    <Skeleton
                      h='417px'
                      speed={1}
                      opacity={0.4}
                      key={`skeleton-${item}`}
                    />
                  ))}
                </Grid>
              </Box>
            )}
            {/* loaded, not empty */}
            <Box pb='10px'>
              <HStack
                display={loading ? "none" : "flex"}
                borderBottom='0.25px solid #858585'
                pb={[3, 3, 10]}
                mb={[5, 5, 10]}
                // spacing="23px"
              >
                <Text
                  mr='auto'
                  color='#3C4859'
                  fontSize={[18, 18, 32, 32]}
                  fontWeight='500'
                >
                  My Portfolio
                </Text>

                <HStack display={["none", "none", "none", "flex"]} spacing={5}>
                  <MenuDropOption
                    options={categories}
                    selectedOption={
                      !selectedCategory ? "All Sectors" : selectedCategory
                    }
                    setOption={payload =>
                      payload === "All"
                        ? setselectedCategory("")
                        : setselectedCategory(payload)
                    }
                  />
                  <MenuDropOption
                    options={stages}
                    selectedOption={
                      !selectedStage ? "All Stages" : selectedStage
                    }
                    setOption={payload =>
                      payload === "All"
                        ? setselectedStage("")
                        : setselectedStage(payload)
                    }
                  />
                </HStack>
                <Button
                  padding={0}
                  _hover={{ background: "transparent" }}
                  _focus={{ outline: "none" }}
                  variant='ghost'
                  display={["flex", "flex", "flex", "none"]}
                  onClick={() => setshowFilter(true)}
                  border='1px solid #383838'
                  rounded={4}
                  width='42px'
                  height='33px'
                  justifyContent='center'
                  alignItems='center'
                >
                  <ShowFilterOptionsIcon />
                </Button>
              </HStack>
              {!loading && investments.length > 0 && (
                <Grid
                  gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]}
                  gap={["18px", "18px", "18px", "50px"]}
                >
                  {investments.map((item, index) => (
                    <InvestedStartUpCard
                      key={`portfolio-card-${index}`}
                      startup={item}
                    />
                  ))}
                </Grid>
              )}
            </Box>
            {/* not loading but empty */}
            {!loading && investments.length === 0 && (
              <Flex
                background='rgba(45, 127, 249, 0.05)'
                flexDirection='column'
                alignItems='center'
                padding='56px 30px'
                marginBottom='100px'
              >
                <Image
                  src={illustration}
                  width={["160px", "initial", "408px"]}
                />
                <Box maxWidth='429px'>
                  <Text
                    textAlign='center'
                    color='#4E5457'
                    fontSize={32}
                    fontWeight={600}
                    mt='45px'
                    display={["none", "block", "block", "block"]}
                  >
                    Start building your portfolio
                  </Text>
                  <Text
                    textAlign='center'
                    color='#68686C'
                    fontSize={[12, 16, 16, 16]}
                    fontWeight={400}
                    mt='8px'
                    mb='45px'
                  >
                    Get started by investing in startups vetted by CcHUB and get
                    returns through a simplified process.
                  </Text>
                  <PrimaryButton
                    label='Explore Startups'
                    onClick={() => history.push("/dashboard/startups")}
                  />
                </Box>
              </Flex>
            )}
          </Box>
        </Box>
      </Container>
      {/* filter starts here */}
      <FilterModalMobile show={showFilter} close={() => setshowFilter(false)}>
        <VStack alignItems='flex-start' spacing={8}>
          {/*  */}
          <HStack justifyContent='space-between' width='100%'>
            <Box color='#3C4859'>
              <Text fontSize={12} fontWeight='400'>
                Showing
              </Text>
              <Text fontWeight='500' fontSize={16}>
                {selectedStage}
              </Text>
            </Box>
            <Button
              variant='ghost'
              color='#2D7FF9'
              fontWeight='500'
              padding={0}
              _focus={{ outline: "none" }}
              onClick={() => {
                setoptionsType("stages");
                setshowFilter(false);
                setshowOptions(true);
              }}
            >
              Edit
            </Button>
          </HStack>
          {/*  */}
          <HStack justifyContent='space-between' width='100%'>
            <Box color='#3C4859'>
              <Text fontSize={12} fontWeight='400'>
                Showing
              </Text>
              <Text fontWeight='500' fontSize={16}>
                {selectedCategory}
              </Text>
            </Box>
            <Button
              variant='ghost'
              color='#2D7FF9'
              fontWeight='500'
              padding={0}
              _focus={{ outline: "none" }}
              onClick={() => {
                setoptionsType("categories");
                setshowFilter(false);
                setshowOptions(true);
              }}
            >
              Edit
            </Button>
          </HStack>
        </VStack>
      </FilterModalMobile>
      {/* filter ends here */}
      {/* stages options */}
      {optionsType === "stages" && (
        <OptionsModal
          options={stages}
          show={showOptions}
          close={() => setshowOptions(false)}
          title='Select Stages(s)'
          selected={selectedStage}
          selectOption={payload => setselectedStage(payload)}
        />
      )}
      {/* categories options */}
      {optionsType === "categories" && (
        <OptionsModal
          options={categories}
          show={showOptions}
          close={() => setshowOptions(false)}
          title='Select Sector(s)'
          selected={selectedCategory}
          selectOption={payload => setselectedCategory(payload)}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent width='90%' maxWidth='900px'>
          <ModalHeader py='0.5rem' textAlign='center'>
            How it Works
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={0} py={0}>
            <video width='100%' controls muted='muted' autoplay allowfullscreen>
              <source src={`${baseUrl}/v1/howTo`} type='video/mp4' />
            </video>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Portfolio;